
import { Component, Vue, } from 'vue-property-decorator'
import CreateComponent from './CreateDialog.vue'

@Component({
  components: {
    CreateComponent,
  },
  computed: {},
})
export default class DetailCategoryComponent extends Vue {}
